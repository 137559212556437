import Product from '@models/Catalog/Product';

export default async function normalizeProduct(
    product,
    {
        createProductPath,
        createCategoryPath,
        stringifyCategoryUrl,
        imaginator,
        getStaticFileUrlWithHash,
        abTests,
        dateHelper,
        t,
        configProvider,
    },
    { locale, currency, storeViewTimezone },
    withDesignSystem = false
) {
    const normalizedProduct = { ...product };

    const productModel = new Product(
        normalizedProduct,
        locale,
        currency,
        storeViewTimezone,
        createProductPath,
        createCategoryPath,
        stringifyCategoryUrl,
        imaginator,
        getStaticFileUrlWithHash,
        abTests,
        dateHelper,
        t,
        configProvider,
        withDesignSystem
    );

    const details = await productModel.buildDetails();

    return details;
}
