import { MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT_BFF } from '@configs/megatron-client';

import { createMegatronEndpointUrl } from '@assets/megatron-client/create-megatron-endpoint-url';

const MEGATRON_CHECKOUT_GCP_TEST_NAME = 'mod_all_checkout_gcp';

const getMegatronCheckoutBffEndpoint = () => {
    return process.server ? 'megatron-modivo-bff.prod.modivo.cloud' : '/t-api-bff';
};

const isMegatronCheckoutGcpTestOn = $abTests =>
    $abTests.getVariant(MEGATRON_CHECKOUT_GCP_TEST_NAME) === 'on';

export const createMegatronCheckoutBffEndpoints = ({
    megatronEndpoint,
    megatronSuffixPath,
    $abTests,
}) => {
    const megatronCheckoutBffEndpoint = isMegatronCheckoutGcpTestOn($abTests)
        ? getMegatronCheckoutBffEndpoint()
        : megatronEndpoint;

    return {
        [MEGATRON_CLIENT_ENDPOINT_NAME_CHECKOUT_BFF]: {
            uri: createMegatronEndpointUrl(megatronCheckoutBffEndpoint, megatronSuffixPath),
            useErrorCallback: false,
            context: {
                useAuthorization: true,
                shouldAddXInstanceIdHeader: true,
            },
        },
    };
};
