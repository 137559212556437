import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { ACTIVE_MAIN_CATEGORY_KEY, GENDER_COOKIE_NAME, SECONDS_IN_YEAR } from '@configs/storage';
import { ROOT_CATEGORY_SLUG } from '@localeConfig/keys';

import {
    MEGA_MENU_CHILD_ACCORDIONS_AB_TEST_NAME,
    DOUBLE_COLUMNS_ABTEST,
} from '@header/configs/ab-tests';

import { DEFAULT_MAIN_CATEGORY_PIM_CODE } from '@search/configs/defaults/search';

import { types } from './mutations';

const MODULE_NAME = 'navigation';

export default {
    setNavigationCategories({ commit }, defaultCategory) {
        const { children: mainCategoryChildren, pim } = defaultCategory;

        const {
            slug: mainCategoryPimSlug = this.app.$configProvider.getConfig(ROOT_CATEGORY_SLUG),
        } = pim || {};

        commit(types.SET_NAVIGATION_CATEGORIES, {
            mainCategoryChildren,
            mainCategoryPimSlug,
        });
    },

    setCurrentNavigationPath({ commit }, currentNavPath) {
        commit(types.SET_CURRENT_MENU_NAV_PATH, currentNavPath);
    },

    setCurrentNavigationType({ commit }, currentNavigationType) {
        commit(types.SET_CURRENT_MENU_NAV_TYPE, currentNavigationType);
    },

    setCurrentNavColor({ commit }, currentNavColor) {
        commit(types.SET_CURRENT_NAV_COLOR, currentNavColor);
    },

    setActiveMainCategory({ commit }, activeMainCategoryPimSlug) {
        if (process.client && activeMainCategoryPimSlug) {
            this.app.$storage.setItem(ACTIVE_MAIN_CATEGORY_KEY, activeMainCategoryPimSlug);
            this.app.$cookies.set(GENDER_COOKIE_NAME, activeMainCategoryPimSlug, {
                path: '/',
                maxAge: SECONDS_IN_YEAR,
            });
        }

        commit(types.SET_ACTIVE_MAIN_CATEGORY_PIM_SLUG, activeMainCategoryPimSlug);
    },

    async initActiveMainCategory({ commit, dispatch, state, rootState }) {
        const allowDoubleColumns = this.app.$abTests.getVariant(DOUBLE_COLUMNS_ABTEST) === 'on';
        const isAccordionAbTestOn =
            this.app.$abTests.getVariant(MEGA_MENU_CHILD_ACCORDIONS_AB_TEST_NAME) === 'on';

        commit(types.SET_ALLOW_DOUBLE_COLUMNS, allowDoubleColumns);
        commit(types.SET_IS_ACCORDION_AB_TEST_ON, isAccordionAbTestOn);

        if (state.activeMainCategoryPimSlug) {
            return;
        }

        const mainCategory = rootState.search?.category?.url
            ? rootState.search?.category?.url.split('/')[0]
            : this.app.$cookies.get(GENDER_COOKIE_NAME);

        if (mainCategory) {
            const result = await dispatch('setNavigationContext', mainCategory);

            if (result) {
                return;
            }
        }

        const { pim: { slug: activeMainCategoryPimSlug } = {} } =
            state.categories.find(
                category => category.pim?.code === DEFAULT_MAIN_CATEGORY_PIM_CODE
            ) || {};

        if (activeMainCategoryPimSlug) {
            commit(types.SET_ACTIVE_MAIN_CATEGORY_PIM_SLUG, activeMainCategoryPimSlug);
        }
    },

    async getNavCategoriesData({ dispatch, state }) {
        const { defaultCategoryId } = state;
        let defaultCategory = [];

        try {
            defaultCategory = await this.app.$services.navigation.getNavCategories(
                defaultCategoryId
            );
        } catch (err) {
            this.app.$errorHandler.captureStoreError(
                MODULE_NAME,
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'navigation.getNavCategories',
                },
                {
                    defaultCategoryId,
                    action: 'navigation.getNavCategories',
                }
            );
        }

        if (defaultCategory && defaultCategory.length) {
            const mainCategoriesWrapper = defaultCategory[0];

            dispatch('setNavigationCategories', mainCategoriesWrapper);

            return true;
        }

        this.app.$errorHandler.captureStoreMessage(
            MODULE_NAME,
            'Error getting navigation categories',
            {
                [ERROR_ACTION_TAG_NAME]: 'setNavigationCategories',
            },
            {
                defaultCategory,
            }
        );

        return false;
    },

    setMenuDesktopIsOpen({ commit }, isMenuOpen) {
        commit(types.TOGGLE_DESKTOP_MENU, isMenuOpen);
    },

    setNavigationContext({ dispatch, getters }, mainCategorySlug) {
        if (
            mainCategorySlug &&
            mainCategorySlug !== this.app.$configProvider.getConfig(ROOT_CATEGORY_SLUG) &&
            mainCategorySlug !== getters.activeMainCategory?.slug &&
            getters.isMainCategory(mainCategorySlug)
        ) {
            dispatch('setActiveMainCategory', mainCategorySlug);

            return true;
        }

        return false;
    },

    setIsSearchInputActive({ commit }, isSearchInputActive) {
        commit(types.SET_IS_SEARCH_INPUT_ACTIVE, isSearchInputActive);
    },

    setIsSortFilterPanelActive({ commit }, isSortFilterPanelActive) {
        commit(types.SET_IS_SORT_FILTER_PANEL_ACTIVE, isSortFilterPanelActive);
    },
};
