import {
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DOUBLE_SIZE_TYPE,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    FOOTWEAR_SIZE_ATTRIBUTE_NAME,
    CLOTHES_SIZE_ATTRIBUTE_NAME,
    HEALTH_BEAUTY_SIZE_ATTRIBUTE_NAME,
    HOME_DECOR_SIZE_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    OUTLET_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';

export const DEFAULT_MAIN_CATEGORY_PIM_CODE = 10000;

export const DEFAULT_SEARCH_SELECT_FIELDS = [
    BADGES_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    CLOTHES_SIZE_ATTRIBUTE_NAME,
    COLOR_ATTRIBUTE_NAME,
    DOUBLE_SIZE_TYPE,
    FOOTWEAR_SIZE_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    PRODUCT_GROUP_ASSOCIATED_ATTRIBUTE_NAME,
    SIZE_TYPE_NAME_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    FOOTWEAR_SIZE_ATTRIBUTE_NAME,
    CLOTHES_SIZE_ATTRIBUTE_NAME,
    HEALTH_BEAUTY_SIZE_ATTRIBUTE_NAME,
    HOME_DECOR_SIZE_ATTRIBUTE_NAME,
    PRODUCT_COLOR_VARIANTS_COUNT_ATTRIBUTE_NAME,
    SHORT_NAME_ATTRIBUTE_NAME,
    OUTLET_ATTRIBUTE_NAME,
];
