<template>
    <div class="footer-content">
        <NewsletterFooterSectionWrapper
            v-if="!isMobileEvaluated || isMobile"
            class="newsletter-mobile"
        />

        <ContainerContent v-if="Object.keys(config).length" class="footer-container">
            <LazyHydrate v-if="!isMobile" :when-visible="true">
                <div class="menu-desktop">
                    <FooterMenu :menu-sections="config.footerSections" />
                </div>
            </LazyHydrate>

            <SocialIcons
                :facebook-url="config.configData.facebookUrl"
                :instagram-url="config.configData.instagramUrl"
                :tiktok-url="config.configData.tiktokUrl"
                :pinterest-url="config.configData.pinterestUrl"
                :youtube-url="config.configData.youtubeUrl"
                class="social"
            />

            <div class="newsletter-with-apps">
                <NewsletterFooterSectionWrapper v-if="!isMobile" class="newsletter-desktop" />

                <LazyHydrate v-if="!isMobileEvaluated || isMobile" :when-visible="true">
                    <div class="menu-mobile">
                        <FooterMenu :menu-sections="config.footerSections" />
                    </div>
                </LazyHydrate>
                <LazyHydrate :when-visible="true">
                    <div class="mobile-apps">
                        <MobileAppsLinksProvider
                            v-if="showMobileAppInfoForStoreCodes"
                            #default="{ footerAppUrl, footerAppGalleryUrl }"
                        >
                            <DownloadApp
                                v-if="footerAppUrl || footerAppGalleryUrl"
                                :play-url="footerAppUrl"
                                :app-store-url="footerAppUrl"
                                :app-gallery-url="footerAppGalleryUrl"
                                :is-mobile="isMobile"
                            />
                        </MobileAppsLinksProvider>
                    </div>
                </LazyHydrate>
            </div>
        </ContainerContent>

        <div class="footer-bottom">
            <ContainerContent
                class="bottom-wrapper"
                :class="{ 'has-legal-info-links': hasLegalInfoLinks }"
            >
                <LegalInformationLinks v-if="hasLegalInfoLinks" class="links" />
                <Copyright :content="COPYRIGHT_CONTENT" class="footer-copyright" />
                <Dropdown
                    v-model="selected"
                    :options="dropdownOptions"
                    :direction-up="true"
                    size="small"
                    class="countries-dropdown"
                    @input="changeSiteLangVersion($event)"
                >
                    <template #selected>
                        <span class="dropdown-item">
                            <SvgIcon width="16px" height="16px" class="dropdown-item-icon">
                                <component :is="currentLocale.flagName" />
                            </SvgIcon>

                            <span>{{ currentLocale.label }}</span>
                        </span>
                    </template>
                    <template #option="{ option }">
                        <span class="dropdown-item">
                            <SvgIcon width="16px" height="16px" class="dropdown-item-icon">
                                <component :is="option.flagName" />
                            </SvgIcon>
                            <span>{{ option.label }}</span>
                        </span>
                    </template>
                </Dropdown>
            </ContainerContent>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { LEGAL_INFORMATION_FIRST, LEGAL_INFORMATION_SECOND } from '@localeConfig/keys';

import {
    COPYRIGHT_CONTENT,
    MAX_COLUMNS_TO_DISPLAY,
    PSB_FOOTER_CONFIGURATION,
} from '@configs/footer';

import { CMS_FOOTER_CONFIGURATION } from '@modules/page-builder/page-builder.config';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import flagIcons from '@assets/flagIcons';
import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import showMobileAppInfo from '@mixins/showMobileAppInfo';
import locales from '@mixins/locales';

import MobileAppsLinksProvider from '@functionals/MobileAppsLinksProvider/MobileAppsLinksProvider';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import Dropdown from '@molecules/Dropdown/Dropdown';
import Copyright from '@molecules/Copyright/Copyright';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SocialIcons from '@molecules/SocialIcons/SocialIcons';
import DownloadApp from '@molecules/DownloadApp/DownloadApp';

import FooterMenu from '@organisms/FooterMenu/FooterMenu';
import NewsletterFooterSectionWrapper from '@molecules/NewsletterFooterSectionWrapper/NewsletterFooterSectionWrapper';
import LegalInformationLinks from '@molecules/LegalInformationLinks/LegalInformationLinks';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');

export default {
    name: 'FooterContent',

    components: {
        NewsletterFooterSectionWrapper,
        FooterMenu,
        ContainerContent,
        MobileAppsLinksProvider,
        DownloadApp,
        SocialIcons,
        Copyright,
        LegalInformationLinks,
        SvgIcon,
        Dropdown,
        ...flagIcons,
        LazyHydrate,
    },

    mixins: [showMobileAppInfo, locales],

    data() {
        return {
            selected: '',
        };
    },

    computed: {
        ...mapCmsBlockGetters(['pageBuilderBlockById']),
        ...mapState(['isMobile', 'isMobileEvaluated']),

        footerDataCmsPage() {
            return this.pageBuilderBlockById(PSB_FOOTER_CONFIGURATION);
        },

        config() {
            try {
                const cmsFooterConfiguration = this.footerDataCmsPage.find(
                    ({ name }) => name === CMS_FOOTER_CONFIGURATION
                );

                if (!cmsFooterConfiguration) {
                    return {};
                }

                const {
                    slots: { default: rawFooterSections },
                } = cmsFooterConfiguration;

                const footerSections = rawFooterSections
                    .slice(0, MAX_COLUMNS_TO_DISPLAY)
                    .map(section => {
                        const {
                            props: { heading: sectionName },
                            slots: { default: footerLinks },
                        } = section;

                        const links = footerLinks.map(link => link.props);

                        return { sectionName, links };
                    });

                return {
                    configData: cmsFooterConfiguration?.props || {},
                    footerSections,
                };
            } catch (err) {
                this.$errorHandler.captureError(err, {
                    [ERROR_ACTION_TAG_NAME]: 'Footer.config',
                });

                return {};
            }
        },

        hasLegalInfoLinks() {
            return (
                [
                    this.$configProvider.getConfig(LEGAL_INFORMATION_FIRST),
                    this.$configProvider.getConfig(LEGAL_INFORMATION_SECOND),
                ].filter(legalInformation => !!legalInformation).length > 0
            );
        },

        dropdownOptions() {
            return this.availableLocales.map(({ label, fullUrl: value, flagName }) => ({
                label,
                value,
                flagName,
            }));
        },
    },

    beforeCreate() {
        this.COPYRIGHT_CONTENT = COPYRIGHT_CONTENT;
    },

    methods: {
        changeSiteLangVersion(selected) {
            if (window.location.origin !== selected.value) {
                window.location.href = selected.value;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$footer-padding-top: 80px;
$footer-padding-bottom: 28px;

.footer-content {
    @apply py-5 bg-gray5;

    .footer-container {
        @apply flex-col flex-nowrap justify-between border-t-1 border-gray4;
    }

    .newsletter-mobile {
        @apply px-3 mb-6;
    }

    .menu-desktop,
    .newsletter-desktop {
        @apply hidden;
    }

    .mobile-apps,
    .social,
    .menu-mobile {
        @apply mt-5;
    }

    .footer-bottom {
        @apply border-t-1 border-border mt-30p pt-5;
    }

    .bottom-wrapper {
        @apply flex flex-col items-center flex-nowrap;
    }

    .links {
        @apply mb-3;
    }

    .dropdown-item {
        @apply flex items-center text-xs min-w-xxl leading-xs;

        .flag {
            @apply rounded-50 mr-2;
        }
    }
    .dropdown-item-icon {
        @apply mr-10p rounded-50;

        :deep(g) {
            -webkit-mask-image: none;
            mask-image: none;
        }
    }

    .countries-dropdown {
        &:deep() .select .selected .toggle .icons {
            @apply ml-0;
        }
    }

    @screen lg {
        padding-top: $footer-padding-top;
        padding-bottom: $footer-padding-bottom;

        .footer-container {
            @apply flex-row border-0;
        }

        .footer-bottom {
            padding-top: $footer-padding-bottom;
        }

        .menu-mobile,
        .newsletter-mobile {
            @apply hidden;
        }

        .menu-desktop,
        .newsletter-desktop {
            @apply block;
        }

        .social {
            @apply mt-0 mr-3;
        }

        .countries-dropdown {
            @apply justify-self-end mr-ui-7;
        }

        .bottom-wrapper {
            @apply grid w-full;
            grid-template-columns: 1fr 1fr 1fr;
        }

        .footer-copyright {
            @apply col-start-2;
        }

        .links {
            @apply mb-0;
        }
    }

    @screen xl {
        .menu-desktop {
            @apply w-1/2;
        }

        .social {
            @apply mr-0 w-1/4;
        }

        .newsletter-with-apps {
            @apply w-1/4;
        }
    }
}
</style>
