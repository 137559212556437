import { MegatronHttpClient } from '@eobuwie/nuxt-http';

import {
    MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO,
    MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST,
    MEGATRON_CLIENT_ENDPOINT_NAME_NEAREST_PICKUP_POINTS,
    MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING,
    MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE,
    MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH,
    MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH_GCP,
    MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG,
    MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH,
} from '@configs/megatron-client';

import {
    CHANNEL_HEADER,
    APP_NAME_HEADER,
    PLATFORM_HEADER,
    getAppVersionHeader,
} from '@configs/http-headers';

import {
    MEGATRON_SUFFIX_PATH,
    SEARCH_SUFFIX_PATH,
    SEARCH_GCP_SUFFIX_PATH,
    PRODUCT_SEARCH_SUFFIX_PATH,
    MAGENTO_SUFFIX_PATH,
} from '@configs/x3/config-keys';

import { getSearchAbtestHeader } from '@search/utils/ab-tests';

import { createMegatronCheckoutBffEndpoints } from '@cherokee-assets/megatron';

import { getRmaMegatronConfig } from '@rma/megatron/get-rma-megatron-config';

import {
    REST_SUFFIX_PATH,
    SYNERISE_SUFFIX_PATH,
    MODIVO_SMAUG_SUFFIX_PATH,
    createMegatronEndpointUrl,
    createMegatronTrustmateEndpoints,
} from '@assets/megatron-client/create-megatron-endpoint-url';
import { getSerializedWhitelistedCookies } from '@assets/cookies';
import { getInterceptors } from '@assets/nuxt-http/interceptors';
import { isTTFBTestOn } from '@assets/performance';
import { isTrustmateProxyTestOn } from '@assets/reviews';
import { filterAvailableStoreViews } from '@assets/store-views';
import { getMegatronEndpoint, isMegatronKeepAliveTestOn } from '@assets/abTests';

export default (
    { store, env, app, $config, $errorHandler, $cookies, req, $mobileApp, $avatar },
    inject
) => {
    const { $abTests, $t, $correlation, $configProvider } = app;

    const helpers = {
        store,
        $mobileApp,
        $cookies,
        $router: app.router,
        $config,
        $abTests,
        $errorHandler,
        $t,
        $correlation,
        $avatar,
    };

    const cookieHeader = {};

    if (process.server && typeof req.headers.cookie === 'string') {
        const serializedWhitelistedCookies = getSerializedWhitelistedCookies(
            $cookies.nodeCookie.parse(req.headers.cookie)
        );

        if (serializedWhitelistedCookies) {
            cookieHeader.cookie = serializedWhitelistedCookies;
        }
    }

    const { megatronEndpoint: oldMegatronEndpoint, megatronDebug, storeViews } = $config;

    const shouldMeasurePerformance = isTTFBTestOn($abTests);
    const megatronEndpoint = getMegatronEndpoint($abTests, oldMegatronEndpoint);

    const visibleStoreViews = filterAvailableStoreViews(storeViews);

    const countries = visibleStoreViews.map(({ locale }) => locale.split('_')[1].toLowerCase());

    const {
        MEGATRON_SUFFIX_PATH: megatronSuffixPath,
        SEARCH_SUFFIX_PATH: searchSuffixPath,
        SEARCH_GCP_SUFFIX_PATH: searchGcpSuffixPath,
        PRODUCT_SEARCH_SUFFIX_PATH: productSearchSuffixPath,
        MAGENTO_SUFFIX_PATH: magentoSuffixPath,
    } = $configProvider.getConfig([
        MEGATRON_SUFFIX_PATH,
        SEARCH_SUFFIX_PATH,
        SEARCH_GCP_SUFFIX_PATH,
        PRODUCT_SEARCH_SUFFIX_PATH,
        MAGENTO_SUFFIX_PATH,
    ]);

    const { endpoints: rmaEndpoints, interceptors: rmaInterceptors } = getRmaMegatronConfig({
        store,
        storeCode: app.i18n.locale,
        megatronEndpoint,
        createMegatronEndpointUrl,
    });

    const featureModulesInterceptors = [rmaInterceptors];

    const megatronClient = new MegatronHttpClient({
        endpoints: {
            [MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, megatronSuffixPath),
                useErrorCallback: false,
                context: {
                    useAuthorization: false,
                },
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_SMAUG]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, MODIVO_SMAUG_SUFFIX_PATH),
                useErrorCallback: false,
                context: {
                    useAuthorization: true,
                },
                headers: {
                    ...$avatar.getInstanceIdHeader(),
                },
            },
            [MEGATRON_CLIENT_ENDPOINT_NAME_MAGENTO_REST]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, magentoSuffixPath),
                useErrorCallback: false,
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_NEAREST_PICKUP_POINTS]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, REST_SUFFIX_PATH),
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_PACKAGE_TRACKING]: {
                uri: createMegatronEndpointUrl(megatronEndpoint),
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_SYNERISE]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, SYNERISE_SUFFIX_PATH),
                context: {
                    useAuthorization: false,
                },
                useErrorCallback: false,
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, searchSuffixPath),
                context: {
                    useAuthorization: false,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...$avatar.getInstanceIdHeader(),
                    ...getSearchAbtestHeader($abTests),
                },
                useErrorCallback: false,
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_SEARCH_GCP]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, searchGcpSuffixPath),
                context: {
                    useAuthorization: false,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...$avatar.getInstanceIdHeader(),
                    ...getSearchAbtestHeader($abTests),
                },
                useErrorCallback: false,
            },

            [MEGATRON_CLIENT_ENDPOINT_NAME_PRODUCT_SEARCH]: {
                uri: createMegatronEndpointUrl(megatronEndpoint, productSearchSuffixPath),
                context: {
                    useAuthorization: false,
                },
                headers: {
                    ...PLATFORM_HEADER,
                    ...APP_NAME_HEADER,
                    ...getAppVersionHeader(env.appVersion),
                    ...$avatar.getInstanceIdHeader(),
                    ...getSearchAbtestHeader($abTests),
                },
                useErrorCallback: false,
            },

            ...createMegatronCheckoutBffEndpoints({
                megatronEndpoint,
                megatronSuffixPath,
                $abTests,
            }),

            ...createMegatronTrustmateEndpoints({
                countries,
                megatronEndpoint,
                isNewProxyEnabled: isTrustmateProxyTestOn($abTests),
            }),

            ...rmaEndpoints,
        },
        headers: {
            store: app.i18n.locale,
            ...CHANNEL_HEADER,
            ...cookieHeader,
        },
        debug: megatronDebug,
        interceptors: getInterceptors(
            shouldMeasurePerformance,
            helpers,
            featureModulesInterceptors
        ),
        useKeepAlive: isMegatronKeepAliveTestOn($abTests),
    });

    inject('megatronClient', megatronClient);
};
