export default (_, inject) => {
    const brandHashedFileNames = JSON.parse('{"modivo":[{"assetName":"common","name":"common.85614e..css"},{"assetName":"fonts","name":"fonts.35d4b4..css"}],"eobuwie":[{"assetName":"common","name":"common.e18e74..css"},{"assetName":"fonts","name":"fonts.18e695..css"}]}');
    const assetsPath = '/pwa/assets/';

    const getCssLinksForBrand = brand => {
        if (!(brand in brandHashedFileNames)) {
            return [];
        }

        const brandFiles = brandHashedFileNames[brand];

        return brandFiles
            .filter(({ name }) => name.endsWith('.css'))
            .map(({ assetName, name }) => ({
                hid: `brand-${assetName}`,
                rel: 'stylesheet',
                href: `${assetsPath}${name}`,
            }));
    };

    inject('hashedFiles', {
        getCssLinksForBrand,
    });
};
