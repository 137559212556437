import { WEB_VIEW, WEB_VIEW_MOBILE_APP } from '@configs/web-view';
import { IS_MOBILE_WEB_VIEW } from '@configs/storage';

import cookies from '@services/cookies';

import { MOBILE_TOKEN_EXPIRED_PAGE_NAME } from '@router/names';

const REFRESH_MOBILE_TOKEN_TIMEOUT = 20000;

const getRefreshAndRedirectFunctions = app => {
    let refreshTokensPromise = null;

    const redirectToTokenExpiredPage = async () => {
        const { store, router } = app;

        await store.dispatch('customer/removeAuthTokens');
        await store.dispatch('customer/setCustomerData', null);

        await router.push({
            name: MOBILE_TOKEN_EXPIRED_PAGE_NAME,
            query: {
                redirect: router.currentRoute.fullPath,
            },
        });
    };

    const setCallbackForMobileApp = (resolve, timeoutId) => {
        window.nativeMobileAppRefreshTokenCallback = async (
            accessToken,
            accessTokenExpirationISO
        ) => {
            try {
                clearTimeout(timeoutId);

                const { $services, store } = app;

                if (!accessToken || !accessTokenExpirationISO) {
                    await redirectToTokenExpiredPage();

                    return resolve(false);
                }

                await $services.auth.saveMobileAuthTokens({
                    accessToken,
                    accessTokenExpirationISO,
                });

                await store.dispatch('customer/setAuthorizationData', {
                    accessToken,
                    accessTokenExpirationISO,
                });

                delete window.nativeMobileAppRefreshTokenCallback;

                return resolve(true);
            } catch {
                await redirectToTokenExpiredPage();

                resolve(false);
            }
        };
    };

    const refreshMobileTokens = async () => {
        if (!process.client) {
            return false;
        }

        if (refreshTokensPromise) {
            return refreshTokensPromise;
        }

        refreshTokensPromise = new Promise(resolve => {
            const timeoutId = setTimeout(() => {
                delete window.nativeMobileAppRefreshTokenCallback;

                redirectToTokenExpiredPage();

                return resolve(false);
            }, REFRESH_MOBILE_TOKEN_TIMEOUT);

            try {
                setCallbackForMobileApp(resolve, timeoutId);

                window.webkit.messageHandlers.modivoAppNamespace.postMessage('refreshToken');
            } catch {
                clearTimeout(timeoutId);

                delete window.nativeMobileAppRefreshTokenCallback;

                redirectToTokenExpiredPage();

                resolve(false);
            }
        });

        refreshTokensPromise.then(() => {
            refreshTokensPromise = null;
        });

        return refreshTokensPromise;
    };

    return {
        refreshMobileTokens,
        redirectToTokenExpiredPage,
    };
};

const handleRoutingByMobileApp = url => {
    if (!process.client) {
        return false;
    }

    try {
        return window.webkit.messageHandlers.modivoAppNamespace.shouldOverrideUrlLoading(url);
    } catch {
        return false;
    }
};

export default ({ app, route }, inject) => {
    const isMobileAppWebViewCookieName = cookies.createCookieWithPrefix(IS_MOBILE_WEB_VIEW);

    const cookieOptions = {
        path: '/',
    };

    const hasMobileAppWebViewCookie = !!app.$cookies.get(isMobileAppWebViewCookieName);

    const hasQueryStringWithMobileAppWebView = route.query?.[WEB_VIEW] === WEB_VIEW_MOBILE_APP;

    if (process.client && hasQueryStringWithMobileAppWebView) {
        app.$cookies.set(isMobileAppWebViewCookieName, true, cookieOptions);
    }

    const hasAuthorizationTokensFromMobileApp = !!app.$cookies.get(
        'has-authorization-tokens-from-mobile-app'
    );

    inject('mobileApp', {
        isWebView: hasMobileAppWebViewCookie || hasQueryStringWithMobileAppWebView,
        hasAuthorizationTokensFromMobileApp,
        ...getRefreshAndRedirectFunctions(app),
        handleRoutingByMobileApp,
    });
};
