<template>
    <div class="header-wrapper">
        <template v-if="!isWebViewMobile">
            <header class="header">
                <HeaderTopBar v-if="!isMobile" class="desktop-top-bar" />

                <HeaderMainBar :placeholder="displayPlaceholderText" class="main-bar" />

                <div class="search-button-wrapper">
                    <HeaderSearchButton :placeholder="$t('Search brand, product, style')" />
                </div>

                <NavigationBar :class="{ 'invisible-state': isHomePage }" class="nav-bar" />

                <HeaderMainCategories v-if="isMainCategoryPage" class="main-cat" />

                <Divider :class="{ 'on-main-cat': isMainCategoryPage }" class="header-divider" />
            </header>

            <Scrim v-show="isDesktopMenuOpen" class="backdrop" />
        </template>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { IS_NEW_SEARCH_PLACEHOLDER_ENABLED } from '@localeConfig/keys';

import { HOME_PAGE_NAME, MAIN_CATEGORY_PAGE_NAME } from '@router/names';

import HeaderMainCategories from '@header-atoms/HeaderMainCategories/HeaderMainCategories';

import HeaderTopBar from '@header-organisms/HeaderTopBar/HeaderTopBar';
import HeaderSearchButton from '@header-organisms/HeaderSearchButton/HeaderSearchButton';
import NavigationBar from '@header-organisms/NavigationBar/NavigationBar';
import HeaderMainBar from '@header-organisms/HeaderMainBar/HeaderMainBar';

import { Divider } from '@modivo-ui/components/Divider/v1';
import { Scrim } from '@modivo-ui/components/Scrim/v1';

const { mapGetters: mapNavigationGetters, mapState: mapNavigationState } = createNamespacedHelpers(
    'navigation'
);
const { mapState: mapAutocompleteState } = createNamespacedHelpers('header/autocomplete');

export default {
    name: 'HeaderWrapper',

    components: {
        HeaderTopBar,
        HeaderMainBar,
        HeaderSearchButton,
        HeaderMainCategories,
        Divider,
        NavigationBar,
        Scrim,
    },

    props: {
        isWebViewMobile: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            placeholderPhrases: [
                'Guess',
                'Nike',
                'Ugg',
                'Pinko',
                'Adidas',
                'Mango',
                'Boss',
                'kowbojki',
                'panterka',
                'sztuczne futro',
                'botki',
                'spódnica midi',
            ],
            currentWordIndex: 0,
            placeholderAnimationText: '',
            isAnimationActive: false,
            typingPlaceholderInterval: null,
            initPlaceholderTimeout: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapNavigationState(['isDesktopMenuOpen', 'isSearchInputActive']),
        ...mapNavigationGetters(['activeMainCategory', 'isNavigationScrimVisible']),
        ...mapAutocompleteState(['query']),

        isHomePage() {
            return this.$route.name === HOME_PAGE_NAME;
        },

        isMainCategoryPage() {
            return this.$route.name === MAIN_CATEGORY_PAGE_NAME;
        },

        displayPlaceholderText() {
            return this.shouldAnimationPlay && this.isAnimationActive
                ? this.placeholderAnimationText
                : this.$t('Search brand, product, style');
        },
        shouldAnimationPlay() {
            if (this.isSearchInputActive || this.query || this.isMobile) {
                return false;
            }

            return (
                !this.isMobile && this.$configProvider.getConfig(IS_NEW_SEARCH_PLACEHOLDER_ENABLED)
            );
        },
    },

    watch: {
        shouldAnimationPlay(shouldPlay) {
            if (shouldPlay) {
                this.startTypingEffect();

                return;
            }

            this.stopTypingEffect();
        },
    },

    mounted() {
        this.initPlaceholderTimeout = setTimeout(() => {
            if (this.shouldAnimationPlay && !this.isAnimationActive) {
                this.startTypingEffect();
            }
        }, 3000);
    },

    methods: {
        startTypingEffect() {
            const currentWord = this.placeholderPhrases[this.currentWordIndex];
            let characterIndex = 0;
            const currentWorldLength = currentWord.length;

            this.isAnimationActive = true;

            const addCharacter = () => {
                if (characterIndex < currentWorldLength) {
                    this.placeholderAnimationText += currentWord[characterIndex];
                    characterIndex += 1;
                    this.typingPlaceholderInterval = setTimeout(addCharacter, 100);
                } else {
                    this.typingPlaceholderInterval = setTimeout(this.startDeletingEffect, 800);
                }
            };

            addCharacter();
        },

        startDeletingEffect() {
            const currentWord = this.placeholderPhrases[this.currentWordIndex];
            let characterIndex = currentWord.length;

            const removeCharacter = () => {
                if (characterIndex > 0) {
                    this.placeholderAnimationText = this.placeholderAnimationText.slice(0, -1);
                    characterIndex -= 1;
                    this.typingPlaceholderInterval = setTimeout(removeCharacter, 50);
                } else {
                    this.currentWordIndex =
                        (this.currentWordIndex + 1) % this.placeholderPhrases.length;
                    this.typingPlaceholderInterval = setTimeout(this.startTypingEffect, 800);
                }
            };

            removeCharacter();
        },
        stopTypingEffect() {
            clearTimeout(this.typingPlaceholderInterval);
            this.placeholderAnimationText = '';
            this.currentWordIndex = 0;
            this.isAnimationActive = false;
        },
    },
};
</script>

<style scoped lang="scss">
.header-wrapper {
    transition: top 0.3s, opacity 0.5s;
    @apply sticky top-ui-0 z-3;

    .header {
        @apply relative bg-ui-container-default-default;
        @apply z-3;
        @apply max-h-[160px];
    }

    .main-bar {
        @apply opacity-100;

        transition: top 0.3s, opacity 0.5s;
    }

    .backdrop {
        @apply z-2 fixed;
    }

    .desktop-top-bar {
        @apply hidden;
    }

    .search-button-wrapper {
        @apply px-ui-4;
    }

    .header-divider {
        @apply mt-ui-4;

        &.on-main-cat {
            @apply mt-ui-0 relative top-[-2px];
        }
    }

    .nav-bar {
        @apply hidden;
    }

    .main-cat {
        @apply px-ui-4;
    }

    @screen lg {
        @apply top-[-48px];

        .header {
            @apply max-h-none;
        }

        .main-cat {
            @apply hidden;
        }

        .desktop-top-bar {
            @apply block;
        }

        .nav-bar {
            @apply block;
            transition: 0.3s;
        }

        .search-button-wrapper {
            @apply hidden;
        }

        .header-divider {
            @apply mt-ui-0;

            &.on-main-cat {
                @apply static;
            }
        }

        .invisible-state {
            @apply opacity-0 h-ui-6;

            :deep(.navigation) {
                @apply pointer-events-none;
            }
        }
    }
}
</style>
