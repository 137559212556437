<template>
    <div class="navigation-content">
        <div class="nav-limiter">
            <div class="link-section">
                <section
                    v-for="(content, index) in categories"
                    :key="content.id"
                    class="link-container"
                    :style="getContainerWidth(content.categoryLinks.length)"
                >
                    <HeaderText
                        :tag="HEADER_TEXT_TAG_H3"
                        :size="HEADER_TEXT_SIZE_M"
                        class="category-title"
                    >
                        {{ content.title }}
                    </HeaderText>

                    <div
                        class="navigation-links-container"
                        :class="{ 'double-column': content.categoryLinks.length > 10 }"
                        :style="
                            getContainerMaxHeight(content.categoryLinks.length, content.showAll)
                        "
                    >
                        <NavigationNestedItem
                            v-for="link in content.categoryLinks"
                            :key="link.path"
                            :category="link"
                            class="nav-item"
                        />

                        <NavigationNestedItem
                            v-if="content.showAll"
                            :category="content.showAll"
                            :data-test-id="NAVIGATION_SHOW_ALL_ITEM"
                        >
                            <span class="view-all">
                                {{ $t('View all') }}
                            </span>
                        </NavigationNestedItem>
                    </div>

                    <Divider
                        v-if="index !== categories.length && !!categories[index + 1]"
                        :variant="DIVIDER_VARIANT"
                        class="nav-divider"
                    />
                </section>
            </div>

            <div v-if="isCmsDisplayed" class="images-container">
                <WithLazyImages
                    :load-condition="isHovered"
                    img-attr-selector="data-psb-lazy-img"
                    img-src-attr="data-src"
                >
                    <StaticBlock :content="cmsBlock.content" />
                </WithLazyImages>
            </div>
        </div>
    </div>
</template>

<script>
import { NAVIGATION_SHOW_ALL_ITEM } from '@types/AutomaticTestIDs';

import WithLazyImages from '@molecules/WithLazyImages/WithLazyImages';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import NavigationNestedItem from '@header-atoms/NavigationItem/NavigationNestedItem';

import { Divider, DIVIDER_VARIANTS } from '@modivo-ui/components/Divider/v1';
import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';

export default {
    name: 'NavigationContent',

    components: {
        StaticBlock,
        NavigationNestedItem,
        Divider,
        WithLazyImages,
        HeaderText,
    },

    props: {
        categories: {
            type: Array,
            required: true,
        },

        cmsBlock: {
            type: Object,
            default: () => {},
        },

        isHovered: {
            type: Boolean,
            default: false,
        },

        isInMobileMenu: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isCmsDisplayed() {
            return !!this.cmsBlock?.content;
        },
    },

    beforeCreate() {
        this.DIVIDER_VARIANT = DIVIDER_VARIANTS.M;
        this.NAVIGATION_SHOW_ALL_ITEM = NAVIGATION_SHOW_ALL_ITEM;
        this.HEADER_TEXT_TAG_H3 = HEADER_TEXT_TAGS.H3;
        this.HEADER_TEXT_SIZE_M = HEADER_TEXT_SIZES.M;
    },

    methods: {
        getContainerMaxHeight(numberOfLinks, isWithShowAll) {
            if (numberOfLinks <= 10 || this.isInMobileMenu) {
                return null;
            }

            let numberOfItems = numberOfLinks;

            if (isWithShowAll) {
                numberOfItems += 1;
            }

            return {
                maxHeight: `${Math.ceil(numberOfItems / 2) * 52 - 4}px`,
            };
        },

        getContainerWidth(numberOfLinks) {
            if (this.isInMobileMenu) {
                return null;
            }

            if (numberOfLinks > 10) {
                return {
                    width: '66.66%',
                };
            }

            return {
                width: '33.33%',
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@theme/resources/mixin/aspect-ratio.scss';

.navigation-content {
    .nav-limiter {
        @apply flex flex-col;
    }

    .category-title {
        @apply lowercase truncate pb-ui-4 px-ui-4;

        &:first-letter {
            @apply uppercase;
        }
    }

    .link-section {
        @apply my-ui-7;
    }

    .view-all {
        @apply font-ui-body-m-bold;
    }

    .nav-divider {
        @apply my-ui-7;
    }

    @screen lg {
        .nav-limiter {
            @apply px-ui-4 w-max-ui-content mx-auto py-ui-5;
            @apply grid;
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        .nav-divider {
            @apply hidden;
        }

        .category-title {
            @apply px-ui-0 pb-ui-2;
        }

        .link-section {
            @apply my-ui-0;
            @apply col-span-3;
            @apply flex;
        }

        .link-container {
            @apply pr-ui-2;
        }

        .navigation-links-container {
            @apply flex flex-col flex-wrap gap-y-ui-1;
        }

        .nav-item {
            @apply w-full;
        }

        .double-column {
            .nav-item {
                @apply w-1/2;
            }
        }

        .images-container {
            @apply col-span-2;

            &:deep() {
                .w-3\/6 {
                    @apply w-1/2;
                }

                .aspect-ratio-box {
                    @include aspect-ratio;
                }

                .aspect-318x318 {
                    @include aspect-ratio-sizing(318, 318);
                }

                .aspect-318x144 {
                    @include aspect-ratio-sizing(318, 144);
                }

                .aspect-300x352 {
                    @include aspect-ratio-sizing(300, 352);
                }
            }
        }
    }
}
</style>
