import { render, staticRenderFns } from "./HeaderSimpleWrapper.vue?vue&type=template&id=1aea8a80&scoped=true&"
import script from "./HeaderSimpleWrapper.vue?vue&type=script&lang=js&"
export * from "./HeaderSimpleWrapper.vue?vue&type=script&lang=js&"
import style0 from "./HeaderSimpleWrapper.vue?vue&type=style&index=0&id=1aea8a80&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aea8a80",
  null
  
)

export default component.exports