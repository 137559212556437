import { SEARCH_HISTORY_KEY } from '@configs/storage';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { addToSearchHistory } from '@assets/search-history';
import { getEngineVariant, JESSICA } from '@search/assets/search-service';
import { COOKIE_AUTOCOMPLETE_POC, COOKIE_SEARCH_BY_CATEGORY } from '@search/assets/ab-tests';
import { stringifySearchUrl } from '@search/assets/path';
import { queryToPhrase } from '@search/assets/search';

import { isAbTestOn } from '@search/utils/ab-tests';

import { types } from './mutations';

export default {
    setQuery({ commit }, query) {
        commit(types.SET_QUERY, query);
    },

    setAutocompleteVisited({ commit }, isVisited = true) {
        commit(types.SET_AUTOCOMPLETE_VISITED, isVisited);
    },

    clearResults({ commit }) {
        commit(types.SET_RESULTS, {});
        commit(types.TOGGLE_ANALYTICS_TRIGGER);
    },

    checkIfIsNewAutocomplete({ commit }) {
        const engineVariant = getEngineVariant(this.app.$abTests);
        const isPocTestOn = isAbTestOn(this.app.$abTests, COOKIE_AUTOCOMPLETE_POC);

        const isPoc = engineVariant !== JESSICA && isPocTestOn;

        commit(types.SET_NEW_AUTOCOMPLETE, isPoc);
    },

    setCapping({ commit }, capping) {
        commit(types.SET_CAPPING, capping);
    },

    async getSearchUrl({ dispatch, state, commit }, { query, categorySlug = null, brand = null }) {
        if (query.trim() === '') {
            return;
        }

        const history = this.app.$storage.getItem(SEARCH_HISTORY_KEY);
        const newHistory = addToSearchHistory(query, history);

        this.app.$storage.setItem(SEARCH_HISTORY_KEY, newHistory);
        this.app.$modals.close('autocomplete');

        commit(types.SET_QUERY, query);

        const formattedQuery = queryToPhrase(query);

        await dispatch('getAutocompleteRedirect', formattedQuery);

        let url;

        if (state.redirect) {
            url = this.$createPagePath(state.redirect);
        } else if (brand) {
            const searchBrandPath = stringifySearchUrl({
                query,
                filters: {
                    [brand.attribute_url]: [brand.slug],
                },
            });

            url = this.$createSearchPath(searchBrandPath);
        } else {
            url = categorySlug
                ? this.$createSearchPath(query, categorySlug)
                : this.$createSearchPath(query);
        }

        return url;
    },

    async openAutocomplete({ commit, state, dispatch }) {
        if (state.autocompleteVisited) {
            this.app.$modals.open('autocomplete');

            return;
        }

        commit(types.SET_CAPPING, true);

        const promises = [];

        if (state.query.length > 1) {
            promises.push(dispatch('getAutocompleteData', state.query));
        }

        promises.push(dispatch('recentlyViewedProducts/initStore', {}, { root: true }));

        const [autocompletePromise] = await Promise.allSettled(promises);

        if (autocompletePromise.value) {
            commit(types.SET_AUTOCOMPLETE_VISITED, true);
        }

        this.app.$modals.open('autocomplete');
    },

    async getAutocompleteData({ rootState, rootGetters, commit, state }, query) {
        const {
            config: {
                storeView: { base_currency_code: currency, locale },
            },
        } = rootState;

        commit(types.SET_IS_QUERYING, true);

        const activeMainCategoryPimCode = rootGetters['navigation/activeMainCategoryPimCode'];

        const isSearchByCategoryTestOn = isAbTestOn(this.app.$abTests, COOKIE_SEARCH_BY_CATEGORY);

        const activeAutocompleteMainCategoryPimCode = isSearchByCategoryTestOn
            ? state.autocompleteActiveMainCategory?.pimCode
            : '';

        try {
            let promise;

            if (state.isNewAutocomplete) {
                promise = this.app.$services.searchService.getPocAutocomplete(
                    query,
                    locale,
                    currency,
                    activeAutocompleteMainCategoryPimCode
                );
            } else {
                promise = this.app.$services.search.getAutocomplete(
                    activeMainCategoryPimCode,
                    query,
                    locale,
                    currency
                );
            }

            const results = await promise;

            commit(types.SET_RESULTS, results);

            if (this.app.$modals.isOpen('autocomplete')) {
                commit(types.TOGGLE_ANALYTICS_TRIGGER);
            }

            return true;
        } catch (err) {
            commit(types.SET_RESULTS, {});
            commit(types.SET_AUTOCOMPLETE_VISITED, false);

            this.$errorHandler.captureError(
                err,
                {
                    [ERROR_ACTION_TAG_NAME]: 'search.getAutocompleteData',
                },
                {
                    query,
                    isNewAutocomplete: state.isNewAutocomplete,
                }
            );

            return false;
        } finally {
            commit(types.SET_IS_QUERYING, false);
        }
    },
    async getAutocompleteRedirect({ rootState, rootGetters, commit }, query) {
        const {
            config: {
                storeView: { base_currency_code: currency, locale },
            },
        } = rootState;
        const activeMainCategoryPimCode = rootGetters['navigation/activeMainCategoryPimCode'];

        try {
            const promise = this.$services.searchService.getAutocompleteRedirect(
                query,
                locale,
                currency,
                activeMainCategoryPimCode
            );

            const redirect = await promise;

            commit(types.SET_SEARCH_REDIRECT, redirect);
        } catch (err) {
            commit(types.SET_SEARCH_REDIRECT, null);

            this.$errorHandler.captureError(err, {
                [ERROR_ACTION_TAG_NAME]: 'search.getAutocompleteRedirect',
            });
        }
    },

    setAutocompleteActiveMainCategory({ commit }, autocompleteMainCategory) {
        commit(types.SET_AUTOCOMPLETE_ACTIVE_MAIN_CATEGORY, autocompleteMainCategory);
    },
};
