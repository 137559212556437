import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import { hotDealAttributeModel } from '@models/Catalog/attributes/hotDeal';

import { formatMarketingContent } from '@assets/product';
import { getUniqueSellers, hasOnlyExternalFirstOffers } from '@assets/product-offers';
import { getNormalizedAttributeModelValues } from '@assets/product-attributes';
import { find } from '@assets/async';

import { light } from '@theme/resources/variables/colors.json';

const PROMOTION_TYPE_1P = '1P';
const PROMOTION_TYPE_3P = '3P';
const WEB_DISTRIBUTION = 'web';

const checkIfCanDisplayPromoAction1P = variants => !hasOnlyExternalFirstOffers(variants);

const checkIfCanDisplayPromoAction3P = variants => {
    const sellers = getUniqueSellers(variants);

    return sellers.length === 1 && sellers[0].internal === false;
};

const hasWebDistributionChannel = channels =>
    Array.isArray(channels) ? channels.some(item => item?.code === WEB_DISTRIBUTION) : false;

const formatSticker = ({
    badgeContent,
    badgeTextColor,
    badgeBackgroundColor,
    marketingLabelContent,
    marketingLabelTextColor,
    marketingLabelBackgroundColor,
    currentTime,
    startDate,
    endDate,
    showDate = true,
    code,
    linkUrl,
    linkText,
    isHotDeal = false,
    isInternal,
    isTimerEnabled,
    $dateHelper,
}) => {
    return {
        formattedBadgeContent: formatMarketingContent(badgeContent),
        badgeContent,
        badgeBackgroundColor,
        badgeTextColor,
        formattedMarketingLabelContent: formatMarketingContent(marketingLabelContent),
        marketingLabelContent,
        marketingLabelBackgroundColor,
        marketingLabelTextColor,
        startDate: $dateHelper.toISO(startDate),
        endDate: $dateHelper.toISO(endDate),
        timeToEnd: $dateHelper.timeDiffCurrentToEnd(endDate, currentTime),
        showDate,
        code,
        isHotDeal,
        isInternal,
        linkUrl,
        linkText,
        isTimerEnabled,
    };
};

export const getHotDealSticker = async ({
    storeViewTimezone,
    locale,
    variants,
    hotDealValue,
    $dateHelper,
}) => {
    const { values: attributeValues, code: attributeCode } = hotDealValue;

    if (!attributeValues || !attributeCode || !storeViewTimezone) {
        return null;
    }

    const canDisplayPromoAction1P = checkIfCanDisplayPromoAction1P(variants);
    const canDisplayPromoAction3P = checkIfCanDisplayPromoAction3P(variants);

    const sellerTypes = attributeValues.modivo_marketplace || [];

    const hasSellerType1P = sellerTypes.some(({ code }) => code === PROMOTION_TYPE_1P);
    const hasSellerType3P = sellerTypes.some(({ code }) => code === PROMOTION_TYPE_3P);

    const isApplicableForSellerType =
        (canDisplayPromoAction1P && hasSellerType1P) ||
        (canDisplayPromoAction3P && hasSellerType3P);

    if (!isApplicableForSellerType || hasSellerType1P === hasSellerType3P) {
        return null;
    }

    const hasAllRequiredValues = Object.entries(hotDealAttributeModel).every(
        ([key, { isOptional }]) => {
            if (isOptional) {
                return true;
            }

            const value = attributeValues[key]?.[locale];

            return !!value || String(value) === '0';
        }
    );

    if (!hasAllRequiredValues) {
        return null;
    }

    const hotDeal = await getNormalizedAttributeModelValues(
        hotDealAttributeModel,
        attributeValues,
        {
            dateHelper: $dateHelper,
            timezone: storeViewTimezone,
            dateFormat: FORMAT_YYYYMMDD_HHMM,
        },
        value => value?.[locale]
    );

    // eslint-disable-next-line max-len
    const currentDeviceTimeInStoreTimezone = await $dateHelper.createCurrentDateInTimezone(
        storeViewTimezone
    );

    const { start_date: startDate, end_date: endDate } = hotDeal;

    const isActive =
        startDate &&
        endDate &&
        (await $dateHelper.isCurrentDateBetweenPassed(
            startDate,
            endDate,
            currentDeviceTimeInStoreTimezone
        ));

    if (isActive) {
        return formatSticker({
            badgeContent: hotDeal.label_text,
            badgeTextColor: hotDeal.label_text_color,
            badgeBackgroundColor: hotDeal.label_color,
            marketingLabelContent: hotDeal.kp_label_text,
            marketingLabelTextColor: hotDeal.kp_label_text_color,
            marketingLabelBackgroundColor: hotDeal.kp_label_color,
            currentTime: currentDeviceTimeInStoreTimezone,
            startDate,
            endDate,
            code: attributeCode,
            isHotDeal: true,
            isInternal: hasSellerType1P,
            $dateHelper,
        });
    }

    return null;
};

export const getPromoActionSticker = async ({
    locale,
    variants,
    promoActionValue,
    storeViewTimezone,
    $dateHelper,
}) => {
    if (!promoActionValue) {
        return null;
    }

    const canDisplayPromoAction1P = checkIfCanDisplayPromoAction1P(variants);
    const canDisplayPromoAction3P = checkIfCanDisplayPromoAction3P(variants);

    try {
        const actions = Object.values(promoActionValue);

        const PROMO_STICKER_DATE_FORMAT = FORMAT_YYYYMMDD_HHMM;

        let currentDeviceTimeInStoreTimezone = null;

        const firstValidAction = await find(actions, async ({ values: action }) => {
            const {
                start_date: startDate,
                end_date: endDate,
                wlepka_kolor: bgColor,
                wlepka_tresc: content,
                kanal_dystrybucji: distributionChannels,
                modivo_marketplace: sellerTypes = [],
            } = action || {};

            const hasSellerType1P = sellerTypes.some(({ code }) => code === PROMOTION_TYPE_1P);
            const hasSellerType3P = sellerTypes.some(({ code }) => code === PROMOTION_TYPE_3P);

            const isApplicableForSellerType =
                (canDisplayPromoAction1P && hasSellerType1P) ||
                (canDisplayPromoAction3P && hasSellerType3P);

            if (
                !startDate ||
                !endDate ||
                !content?.[locale] ||
                !bgColor ||
                !hasWebDistributionChannel(distributionChannels) ||
                !isApplicableForSellerType ||
                hasSellerType1P === hasSellerType3P
            ) {
                return false;
            }

            currentDeviceTimeInStoreTimezone =
                currentDeviceTimeInStoreTimezone ||
                (await $dateHelper.createCurrentDateInTimezone(storeViewTimezone));

            const actionStart = await $dateHelper.createDateFromStringInTimezone(
                startDate,
                PROMO_STICKER_DATE_FORMAT,
                storeViewTimezone
            );

            const actionEnd = await $dateHelper.createDateFromStringInTimezone(
                endDate,
                PROMO_STICKER_DATE_FORMAT,
                storeViewTimezone
            );

            return $dateHelper.isCurrentDateBetweenPassed(
                actionStart,
                actionEnd,
                currentDeviceTimeInStoreTimezone
            );
        });

        if (!firstValidAction) {
            return null;
        }

        const {
            wlepka_tresc: { [locale]: badgeContent },
            action_code: { [locale]: actionCode },
            link_url: { [locale]: linkUrl },
            link_text: { [locale]: linkText },
            show_timer: { [locale]: showTimer },
            wlepka_kolor: backgroundColor,
            start_date: startDate,
            end_date: endDate,
            pokazuj_date: showDate,
            modivo_marketplace: sellerTypes = [],
        } = {
            wlepka_tresc: {},
            action_code: {},
            link_url: {},
            link_text: {},
            show_timer: {},
            wlepka_kolor: null,
            start_date: null,
            end_date: null,
            pokazuj_date: true,
            modivo_marketplace: [],
            ...firstValidAction.values,
        };

        const hasSellerType1P = sellerTypes.some(({ code }) => code === PROMOTION_TYPE_1P);

        const marketingLabelContentValue = firstValidAction.values.tresc_karta_produktu;

        const marketingLabelContent = marketingLabelContentValue?.[locale] || '';

        const actionStart = await $dateHelper.createDateFromStringInTimezone(
            startDate,
            PROMO_STICKER_DATE_FORMAT,
            storeViewTimezone
        );

        const actionEnd = await $dateHelper.createDateFromStringInTimezone(
            endDate,
            PROMO_STICKER_DATE_FORMAT,
            storeViewTimezone
        );

        const isTimerEnabled = showTimer?.code === 'tak';

        return formatSticker({
            badgeContent,
            marketingLabelContent,
            badgeBackgroundColor: backgroundColor,
            badgeTextColor: light,
            marketingLabelBackgroundColor: backgroundColor,
            currentTime: currentDeviceTimeInStoreTimezone,
            startDate: actionStart,
            endDate: actionEnd,
            showDate,
            code: actionCode,
            linkUrl,
            linkText,
            isInternal: hasSellerType1P,
            isTimerEnabled,
            $dateHelper,
        });
    } catch {
        return null;
    }
};
