import { createNamespacedHelpers } from 'vuex';

import { OPTIMIZELY_SCRIPT_SRC } from '@localeConfig/keys';

const { mapState: mapConfigState } = createNamespacedHelpers('config');

export default {
    computed: {
        ...mapConfigState(['globalConfig']),

        isOptimizelyEnabled() {
            return (
                this.optimizelyScriptUrl &&
                this.globalConfig.optimizelyEnabledPages.includes(this.$route.name)
            );
        },

        optimizelyScriptUrl() {
            return this.$configProvider.getConfig(OPTIMIZELY_SCRIPT_SRC);
        },
    },
};
