export const PRODUCT_LIST = 'product-list';
export const PRODUCT_LIST_ITEM = 'product-list-item';
export const PRODUCT_SIZE = 'product-size';

export const CHOOSE_PRODUCT_SIZE = 'choose-product-size';
export const PRODUCT_NAME = 'product-name';

export const CATALOG_NAVIGATION_TREE = 'catalog-navigation-tree';
export const CATALOG_NAVIGATION_TILES = 'catalog-navigation-tiles';
export const CATALOG_NAVIGATION_ITEM_ACTIVE = 'catalog-navigation-item-active';

export const NAVIGATION_ITEM_LINK = 'navigation-item-link';
export const NAVIGATION_NESTED_ITEM_LINK = 'navigation-nested-item-link';
export const NAVIGATION_SHOW_ALL_ITEM = 'navigation-show-all-link';
export const HEADER_MENU_CATEGORY_ITEM = 'header-menu-category-item';
export const HEADER_MENU_SIDE_MODAL = 'header-menu-side-modal';
export const HAMBURGER_MENU_BUTTON = 'hamburger-menu-button';
export const DEPARTMENT_LINK = 'department-link';

export const FILTERS = 'filters';
export const FILTERS_APPLY_BUTTON = 'filters-apply-button';
export const FILTERS_REMOVE_BUTTON = 'filters-remove-button';
export const FILTERS_SHOW_ALL_BUTTON = 'filters-show-all-button';
export const FILTERS_SIZE_CATEGORY = 'filters-size-category';
export const FILTERS_SIZE_CATEGORY_ITEM = 'filters-size-category-item';
export const FILTERS_OPTION_WRAPPER = 'filters-option-wrapper';
export const FILTER_SIZE_TAG_PREFIX = 'filter-size-tag';

export const ADD_TO_CART_BUTTON = 'add-to-cart-button';
export const ADDED_TO_CART_CONTINUE_BUTTON = 'added-to-cart-continue-button';
export const ADDED_TO_CART_GO_TO_CART_BUTTON = 'added-to-cart-go-to-cart-button';

export const CART_EDIT_BUTTON = 'cart-edit-button';
export const CART_ADD_TO_FAVORITES_BUTTON = 'cart-add-to-favorites-button';
export const CART_DELETE_BUTTON = 'cart-delete-button';
export const CART_CONFIRM_BUTTON = 'cart-confirm-button';
export const CART_CANCEL_BUTTON = 'cart-cancel-button';
export const CART_CHANGE_PRODUCT_SIZE = 'cart-change-product-size';
export const CART_APPLY_CODE_BUTTON = 'cart-apply-code-button';
export const CART_PROCEED_TO_CHECKOUT_BUTTON = 'cart-proceed-to-checkout';

export const DECREMENT_QUANTITY_BUTTON = 'decrement-quantity-button';
export const INCREMENT_QUANTITY_BUTTON = 'increment-quantity-button';

export const CHECKOUT_CONTINUE_WITH_FACEBOOK = 'checkout-continue-with-facebook';
export const CHECKOUT_CONTINUE_WITH_GOOGLE = 'checkout-continue-with-google';

export const LOG_IN_BUTTON = 'log-in-button';
export const CONTINUE_AS_GUEST = 'continue-as-guest';
export const REGISTER = 'register';
export const CREATE_ACCOUNT_BUTTON = 'create-account-button';
export const AGREEMENTS_BUTTON = 'agreements-button';
export const CUSTOMER_CONSENTS_BUTTON = 'customer-consents-button';

export const SORT_DROPDOWN = 'sort-dropdown';
export const SORT_MOBILE_SCROLL = 'sort-mobile-scroll';

export const DROPDOWN_SELECTED_BUTTON = 'dropdown-selected-button';
export const DROPDOWN_OPTION = 'dropdown-option';

export const PRODUCT = 'product';
export const PRODUCT_NAME_LINK = 'product-name-link';
export const PRODUCT_NAME_DETAILS_HEADING = 'product-name-details-heading';
export const PRODUCT_PRICE_REGULAR = 'product-price-regular';
export const PRODUCT_PRICE_WRAPPER = 'product-price-wrapper';
export const MAIN_GALLERY = 'main-gallery';
export const MFC_LABEL = 'mfc-label';
export const MFC_JOIN_BUTTON = 'mfc-join-button';
export const MFC_LOGIN_REGISTRATION_MODAL = 'mfc-login-registration-modal';
export const MFC_LEARN_MORE_LINK = 'mfc-learn-more-link';
export const ADD_TO_WISHLIST_BUTTON = 'add-to-wishlist-button';
export const PRODUCT_GALLERY_MODAL = 'product-gallery-modal';
export const SIZE_TABLE_BUTTON = 'size-table-button';
export const SIZE_TABLE_TBODY = 'size-table-tbody';
export const MODAL_ADDED_TO_CART_CONFIRMATION = 'modal-added-to-cart-confirmation';
export const MODAL_ADD_TO_CART_ERROR = 'modal-add-to-cart-error';
export const RATING_WRAPPER = 'rating-wrapper';
export const SEE_REVIEWS = 'see-reviews';
export const NUMBER_OF_REVIEWS = 'number-of-reviews';
export const REVIEWS_LIST = 'reviews-list';
export const REVIEW_ITEM = 'review-item';
export const PRODUCT_PRICE = 'product-price';
export const PRODUCT_FULL_PRICE = 'product-full-price';
export const COLOR_VARIANT = 'color-variant';
export const SIMPLE_SLIDER_SLIDE = 'simple-slider-slide';
export const PRODUCT_COLOR = 'product-color';
export const SELLER_SHIPPING_AND_RETURN_INFO = 'seller-shipping-and-return-info';
export const CART_SHIPPING_AND_RETURN_INFO = 'cart-shipping-and-return-info';
export const PRODUCT_INFO = 'product-info';
export const PRODUCT_CATEGORY_TAG = 'product-category-tag';
export const SHOW_MORE_PICTURES = 'show-more-pictures';
export const PARTNER_NAME = 'partner-name';
export const PRODUCT_SHIPPING = 'product-shipping';
export const PRODUCT_RETURN = 'product-return';
export const PARTNER_INFORMATION_MODAL = 'partner-information-modal';
export const MULTI_STEP_SIDE_MODAL = 'multi-step-side-modal';
export const SIDE_MODAL = 'side-modal';
export const SIDE_MODAL_CLOSE = 'side-modal-close';
export const CLOSE_MODAL = 'close-modal';
export const NOTIFY_BUTTON = 'notify-button';
export const CONFIRMATION_MESSAGE = 'confirmation-message';
export const UNAVAILABLE_BANNER = 'unavailable-banner';
export const FIT_ANALYTICS_BUTTON = 'fit-analytics-button';
