export const types = {
    SET_NAVIGATION_CATEGORIES: 'SET_NAVIGATION_CATEGORIES',
    SET_CURRENT_MENU_NAV_PATH: 'SET_CURRENT_MENU_NAV_PATH',
    SET_ACTIVE_MAIN_CATEGORY_PIM_SLUG: 'SET_ACTIVE_MAIN_CATEGORY_PIM_SLUG',
    SET_IS_MOBILE_MENU_OPEN: 'SET_IS_MOBILE_MENU_OPEN',
    SET_CURRENT_MENU_NAV_TYPE: 'SET_CURRENT_MENU_NAV_TYPE',
    SET_CURRENT_NAV_COLOR: 'SET_CURRENT_NAV_COLOR',
    TOGGLE_DESKTOP_MENU: 'TOGGLE_DESKTOP_MENU',
    SET_IS_SEARCH_INPUT_ACTIVE: 'SET_IS_SEARCH_INPUT_ACTIVE',
    SET_ALLOW_DOUBLE_COLUMNS: 'SET_ALLOW_DOUBLE_COLUMNS',
    SET_IS_ACCORDION_AB_TEST_ON: 'SET_IS_ACCORDION_AB_TEST_ON',
};

export default {
    [types.SET_NAVIGATION_CATEGORIES](state, { mainCategoryChildren, mainCategoryPimSlug }) {
        state.categories = mainCategoryChildren;
        state.mainCategoryPimSlug = mainCategoryPimSlug;
    },

    [types.SET_CURRENT_MENU_NAV_PATH](state, currentNavPath) {
        state.currentNavPath = currentNavPath;
    },

    [types.SET_CURRENT_MENU_NAV_TYPE](state, currentNavType) {
        state.currentNavType = currentNavType;
    },

    [types.SET_ACTIVE_MAIN_CATEGORY_PIM_SLUG](state, activeMainCategoryPimSlug) {
        state.activeMainCategoryPimSlug = activeMainCategoryPimSlug;
    },

    [types.SET_CURRENT_NAV_COLOR](state, currentNavColor) {
        state.currentNavColor = currentNavColor;
    },

    [types.TOGGLE_DESKTOP_MENU](state, isMenuOpen) {
        state.isDesktopMenuOpen = isMenuOpen;
    },

    [types.SET_IS_SEARCH_INPUT_ACTIVE](state, isSearchInputActive) {
        state.isSearchInputActive = isSearchInputActive;
    },

    [types.SET_ALLOW_DOUBLE_COLUMNS](state, allowDoubleColumns) {
        state.allowDoubleColumns = allowDoubleColumns;
    },

    [types.SET_IS_ACCORDION_AB_TEST_ON](state, isAccordionAbTestOn) {
        state.isAccordionAbTestOn = isAccordionAbTestOn;
    },
};
