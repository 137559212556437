import {
    VALID_QUERY_ORDER_KEYS,
    QUERY_ORDER,
    QUERY_ORDER_DIR,
} from '@search/configs/query-keys/sort';

import { ASC, DESC, CREATED, PRICE, DEFAULT } from '@search/configs/defaults/query/sort';

import { QUERY_PAGE_SIZE, QUERY_CURRENT_PAGE } from '@search/configs/query-keys/pagination';

import { VALID_QUERY_SEARCH_KEYS } from '@search/configs/query-keys/search';

import { filtersToParamArray, stringifyCategoryUrl, stringifySearchUrl } from '@search/assets/path';

import { VISUAL_SEARCH_BY_IMG } from '@search/assets/ab-tests';

import { SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';
import { EMPTY, GROUP } from '@search/types/Filters';

import { isAbTestOn } from '@search/utils/ab-tests';

import { MODULE_NAME as CATALOG_MODULE_NAME } from '@analytics-modules/catalog/meta';
import {
    CATEGORY_FILTER_SET,
    CATEGORY_FILTER_PRE_SET,
    CATEGORY_SORT_DEFAULT,
    CATEGORY_SORT_NEWEST,
    CATEGORY_SORT_PRICE_ASC,
    CATEGORY_SORT_PRICE_DESC,
} from '@analytics-modules/catalog/types/Events';

import SyncDateHelper from '@models/DateHelper/SyncDateHelper';

const sortOptionsEventsMap = {
    [DEFAULT]: CATEGORY_SORT_DEFAULT,
    [CREATED]: {
        [DESC]: CATEGORY_SORT_NEWEST,
    },
    [PRICE]: {
        [DESC]: CATEGORY_SORT_PRICE_DESC,
        [ASC]: CATEGORY_SORT_PRICE_ASC,
    },
};

export function shouldBeKeptAfterFiltersChange(param) {
    const VALID_QUERY_KEYS_AFTER_FILTERS_CHANGE = [
        QUERY_PAGE_SIZE,
        ...VALID_QUERY_SEARCH_KEYS,
        ...VALID_QUERY_ORDER_KEYS,
    ];

    return VALID_QUERY_KEYS_AFTER_FILTERS_CHANGE.includes(param);
}

function onCategoryRoute(clearedQuery, filters, categoryUrl, createCategoryPath) {
    const categoryPath = stringifyCategoryUrl({
        categoryUrl,
        filters,
    });

    return {
        path: createCategoryPath(categoryPath),
        query: {
            ...clearedQuery,
        },
    };
}

function onSearchRoute(clearedQuery, filters, query, createSearchPath, isVisualSearchListing) {
    const searchPath = stringifySearchUrl({
        query,
        filters,
    });

    const visualSearchPath = `/s/visual_search${stringifySearchUrl({
        filters,
    })}`;

    return {
        path: isVisualSearchListing ? visualSearchPath : createSearchPath(searchPath),
        query: {
            ...clearedQuery,
        },
    };
}

export function createRouterConfigToApply(
    { $route, $filterManager, $createCategoryPath, $createSearchPath, $store, $abTests },
    isSearchPage
) {
    const { query: routeQuery } = $route;

    const { query: stateQuery, url: categoryUrl } = $store.state.search.category;

    const clearedQuery = Object.keys(routeQuery).reduce((query, urlParam) => {
        const shouldBeKept = shouldBeKeptAfterFiltersChange(urlParam);

        if (shouldBeKept) {
            query[urlParam] = routeQuery[urlParam];
        }

        return query;
    }, {});

    const { selectedExtraFilters, selectedFilters } = $filterManager;

    const filtersToApply = {
        ...selectedExtraFilters,
        ...selectedFilters,
    };

    if (isSearchPage) {
        const isVisualSearchListing =
            isAbTestOn($abTests, VISUAL_SEARCH_BY_IMG) && stateQuery === 'visual_search';

        return onSearchRoute(
            clearedQuery,
            filtersToApply,
            stateQuery,
            $createSearchPath,
            isVisualSearchListing
        );
    }

    return onCategoryRoute(clearedQuery, filtersToApply, categoryUrl, $createCategoryPath);
}

export function getQueryWithoutOrderAndPagination(query) {
    const {
        [QUERY_CURRENT_PAGE]: omitPagination,
        [QUERY_ORDER]: omitOrder,
        [QUERY_ORDER_DIR]: omitOrderDir,
        ...clearedQuery
    } = query;

    return clearedQuery;
}

export function applyOrderSettings(query, sortOption, defaultCode) {
    const { code, order } = sortOption;

    if (code && code !== defaultCode) {
        return {
            ...query,
            [QUERY_ORDER]: code,
            [QUERY_ORDER_DIR]: order,
        };
    }

    return query;
}

export function isFilterActive(storeViewTimezone, startDate, endDate) {
    const dateHelper = new SyncDateHelper(storeViewTimezone, FORMAT_YYYYMMDD_HHMM);

    const actionStart = dateHelper.createDateFromStringInTimezone(startDate);

    const actionEnd = dateHelper.createDateFromStringInTimezone(endDate);

    const currentDeviceTimeInStoreTimezone = dateHelper.createCurrentDateInTimezone();

    return SyncDateHelper.isCurrentDateBetweenPassed(
        actionStart,
        actionEnd,
        currentDeviceTimeInStoreTimezone
    );
}

export function sendAnalyticsFilterSet(
    { $analytics, $filterManager },
    code,
    isSet,
    anotherName = ''
) {
    const eventType = isSet ? CATEGORY_FILTER_SET : CATEGORY_FILTER_PRE_SET;
    const nameToSet = anotherName || code;

    const filter = $filterManager.filters.find(({ code: filterCode }) => code === filterCode);

    if (!filter) {
        return;
    }

    const filters = {};

    if (filter.type === GROUP) {
        filter.filters.forEach(({ code: nestedCode }) => {
            filters[nestedCode] = $filterManager.selectedCodes[nestedCode];
        });
    } else {
        filters[nameToSet] = $filterManager.selectedCodes[code];
    }

    $analytics.moduleEmit(CATALOG_MODULE_NAME, eventType, {
        filterNameAndValueCode: filtersToParamArray(filters).join('|') || `${nameToSet}:`,
    });
}

export function applySearchConfiguration(app) {
    const { areFiltersChanged, areExtraFiltersChanged } = app.$filterManager;
    const isSortChanged = app.$store.getters['search/sort/isSortChanged'];

    if (!areFiltersChanged && !isSortChanged && !areExtraFiltersChanged) {
        return;
    }

    app.$store.dispatch('search/category/setLoading', true);
    let { path: pathToApply, query: queryToApply } = app.$route;

    if (areFiltersChanged || areExtraFiltersChanged) {
        const isSearchPage = app.$route.name === SEARCH_RESULTS_PAGE_NAME;
        const { path, query } = createRouterConfigToApply(app, isSearchPage);

        queryToApply = query;
        pathToApply = path;
    }

    if (isSortChanged) {
        const { selectedSortOption, defaultOrderCode } = app.$store.state.search.sort;

        queryToApply = applyOrderSettings(
            getQueryWithoutOrderAndPagination(queryToApply),
            selectedSortOption,
            defaultOrderCode
        );

        const { code, order } = selectedSortOption;

        app.$analytics.moduleEmit(
            CATALOG_MODULE_NAME,
            sortOptionsEventsMap[code]?.[order] || sortOptionsEventsMap[DEFAULT]
        );
    }

    if (areFiltersChanged) {
        const filterNameAndValueCode =
            filtersToParamArray(app.$filterManager.selectedCodes).join('|') || EMPTY;

        app.$analytics.moduleEmit(CATALOG_MODULE_NAME, CATEGORY_FILTER_SET, {
            filterNameAndValueCode,
        });
    }

    app.$router.push({
        path: pathToApply,
        query: queryToApply,
    });
}
