import { createNamespacedHelpers } from 'vuex';

import { CLIENT_IDENTITY_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import {
    ACTIVE_MAIN_CATEGORY_KEY,
    GTM_CATEGORIES_STORAGE_KEY,
    ORIGINAL_REFERRER_KEY,
} from '@configs/storage';
import { META_ROBOTS_NOINDEX_NOFOLLOW } from '@configs/seo';

import { METRICS_BY_PAGES } from '@configs/page-performance';

import { QUERY_ORDER, QUERY_ORDER_DIR } from '@search/configs/query-keys/sort';
import { QUERY_CURRENT_PAGE } from '@search/configs/query-keys/pagination';

import { CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME } from '@search/routing/names';

import GTMExcluded from '@models/GTMExcluded/GTMExcluded';

import { PAGE } from '@analytics-types/Events';
import { NOTIFICATION_NOT_SUPPORTED } from '@types/Notifications';

import { MODULE_NAME as CLIENT_MODULE } from '@analytics-module/modules/client/meta';
import { USER_TYPE_STATUS_COMPLETE } from '@analytics-module/modules/client/types/Events';

import Deferred from '@core-assets/deferred';
import { getDeviceType } from '@assets/device';
import { setNavigationClientContext } from '@assets/navigation-context';
import { startPerformanceMeasurement } from '@assets/performance';
import loadScript from '@assets/load-script';

import WithCanonicalUrl from '@mixins/WithCanonicalUrl';
import Optimizely from '@mixins/Optimizely';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapSyneriseState, mapActions: mapSyneriseActions } = createNamespacedHelpers(
    'synerise'
);
const { mapState: mapPWAState } = createNamespacedHelpers('pwa');
const { mapState: mapCustomerState, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);
const { mapActions: mapNavigationActions } = createNamespacedHelpers('navigation');
const { mapActions: mapAvatarActions } = createNamespacedHelpers('avatar');

export default ({ sendPageEvent = true } = {}) => ({
    data() {
        return {
            meta: {
                title: '',
                description: '',
                robots: META_ROBOTS_NOINDEX_NOFOLLOW,
            },
            responseStatusCode: 200,
            isPageEventEmittedDeferred: new Deferred(),
            isCustomerFetchedDeferred: new Deferred(),
            isPageEventSent: false,
        };
    },

    mixins: [WithCanonicalUrl, Optimizely],

    beforeCreate() {
        if (process.client) {
            const GTMExcludedProvider = new GTMExcluded(this.$storage);

            this.excludedCategories = GTMExcludedProvider.getCategoriesAnalyticsData();
            this.excludedProviders = GTMExcludedProvider.getProvidersAnalyticsData();
            this.areConsentsAccepted = GTMExcludedProvider.setAreConsentsAccepted();
        }
    },

    created() {
        if (process.client) {
            this.$analytics.waitPromise = this.isPageEventEmittedDeferred.promise;
        }
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency']),
        ...mapSyneriseState(['syneriseConfig', 'syneriseLoaded']),
        ...mapPWAState(['displayMode']),
        ...mapCustomerGetters(['customerUuid', 'isLoggedIn', 'customerHashedEmail', 'userType']),
        ...mapCustomerState(['fetchingCustomerInProgress']),

        shouldSendPageEvent() {
            return sendPageEvent && !this.isPageEventSent;
        },
    },

    beforeRouteLeave(to, from, next) {
        const metricName = METRICS_BY_PAGES?.[to.name];

        if (metricName) {
            startPerformanceMeasurement(metricName);
        }

        this.$correlation.refreshIds();

        next();
    },

    watch: {
        fetchingCustomerInProgress: {
            async handler(fetchingInProgress) {
                if (fetchingInProgress) {
                    return;
                }

                this.isCustomerFetchedDeferred.resolve();
            },

            immediate: true,
        },

        $route: {
            handler(newRoute, oldRoute) {
                if (![CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME].includes(newRoute.name)) {
                    return;
                }

                const QUERY_VIEW_CHANGED_PARAMS = [
                    QUERY_ORDER,
                    QUERY_ORDER_DIR,
                    QUERY_CURRENT_PAGE,
                ];

                const isPathChanged = newRoute.path !== oldRoute?.path;
                const isQueryChanged = QUERY_VIEW_CHANGED_PARAMS.some(
                    param => newRoute.query[param] !== oldRoute?.query?.[param]
                );

                if (isPathChanged || isQueryChanged) {
                    this.sendSynerisePageVisit();
                }
            },
        },
    },

    async beforeMount() {
        this.loadSynerise();

        if (this.shouldSendPageEvent) {
            await this.emitPageEvent();
        }
    },

    methods: {
        ...mapNavigationActions(['setNavigationContext']),
        ...mapAvatarActions(['connectClient']),
        ...mapSyneriseActions(['setSyneriseLoaded']),

        async loadSynerise() {
            const { syneriseConfig, syneriseLoaded } = this;

            const { apiKey, trackerKey, trackerDomain, scriptUrl } = syneriseConfig || {};

            if (!scriptUrl || !apiKey || !trackerKey || !trackerDomain) {
                return;
            }

            if (syneriseLoaded) {
                this.sendSynerisePageVisit();

                return;
            }

            await loadScript(syneriseConfig.scriptUrl);

            const consents = (this.$storage.getItem(GTM_CATEGORIES_STORAGE_KEY) || []).reduce(
                (acc, item) => {
                    acc[item.code] = item.isActive;

                    return acc;
                },
                {}
            );

            if (!window.SR || !window.SyneriseTC) {
                return;
            }

            const booleanToString = value => (value ? 'True' : 'False');

            window.SR.init({
                trackerKey,
                customPageVisit: true,
                dynamicContent: {
                    virtualPage: 0,
                },
                dataLayer: window.dataLayer || [],
                disableNotification: true,
                disableFormMarker: true,
                disableDynamicContent: true,
                trackingDomain: trackerDomain,
            });

            window.SyneriseTC.sendFormData?.('consents', {
                Agreement_service_configuration: booleanToString(
                    consents.category_service_configuration
                ),
                Agreement_processes: booleanToString(consents.category_processes),
                Agreement_advertisement: booleanToString(consents.category_advertisement),
                Agreement_location: booleanToString(consents.category_location),
                Agreement_analysis_and_research: booleanToString(
                    consents.category_analysis_and_research
                ),
            });

            const syneriseLoadedEvent = new CustomEvent('SyneriseLoaded', {
                bubbles: true,
            });

            window.dispatchEvent(syneriseLoadedEvent);

            this.sendSynerisePageVisit();

            this.setSyneriseLoaded(true);
        },

        async sendSynerisePageVisit() {
            await this.isPageEventEmittedDeferred.promise;

            window.SR.event?.pageVisit?.();
        },

        async handleCreateAndConnectAvatarId() {
            try {
                if (!this.$avatar.avatarIdFromCookies) {
                    this.$avatar.createAvatarId();
                }

                if (!this.$avatar.isNewAvatarId) {
                    return;
                }

                await this.isCustomerFetchedDeferred.promise;

                if (!this.isLoggedIn) {
                    return;
                }

                await this.connectClient(this.$avatar.avatarId);
            } catch (err) {
                this.$errorHandler.captureDomainError(CLIENT_IDENTITY_ERROR_DOMAIN, err, {
                    [ERROR_ACTION_TAG_NAME]: 'handleCreateAndConnectAvatarId',
                });
            }
        },

        async sendGAEventWithUserType() {
            await this.isCustomerFetchedDeferred.promise;

            this.$analytics.moduleEmit(CLIENT_MODULE, USER_TYPE_STATUS_COMPLETE, {
                eventLabel: this.userType,
            });
        },

        async emitPageEvent({
            route,
            searchData,
            responseStatusCode,
            pagePagination,
            treeCategory,
            syneriseCampaignIds,
        } = {}) {
            const originalReferrer = this.getOriginalReferrer();

            await this.$analytics.emit(
                PAGE,
                {
                    route: route || this.$route,
                    canonicalUrl: this.canonicalUrl,
                    locale: this.locale,
                    currency: this.currency,
                    robots: this.meta.robots,
                    searchData,
                    pagePagination,
                    deviceType: getDeviceType(),
                    displayMode: this.displayMode,
                    notifications: window.Notification?.permission || NOTIFICATION_NOT_SUPPORTED,
                    responseStatusCode: responseStatusCode || this.responseStatusCode,
                    locationHref: document.location.href,
                    consents: this.areConsentsAccepted,
                    gtmCookiesSettings: {
                        excludedCategories: this.excludedCategories,
                        excludedProviders: this.excludedProviders,
                    },
                    referrer: originalReferrer,
                    syneriseContainerIds: syneriseCampaignIds,
                    treeCategory,
                },
                true
            );

            if (originalReferrer) {
                this.removeOriginalReferrer();
            }

            this.isPageEventEmittedDeferred.resolve();

            this.isPageEventSent = true;

            if (this.$lux) {
                const { name = '' } = route || this.$route;

                if (name) {
                    this.$lux.label(name);
                }

                this.$lux.markLoadTime();
                this.$lux.readyToSend();
            }

            this.sendGAEventWithUserType();
        },

        getOriginalReferrer() {
            return this.$storage.getItem(ORIGINAL_REFERRER_KEY) || '';
        },

        removeOriginalReferrer() {
            this.$storage.removeItem(ORIGINAL_REFERRER_KEY);
        },

        updateNavigationClientContext() {
            const { $store, $storage } = this;

            const clientActiveMainCategory = $storage.getItem(ACTIVE_MAIN_CATEGORY_KEY);

            if (
                clientActiveMainCategory &&
                $store.getters['navigation/isMainCategory'](clientActiveMainCategory)
            ) {
                this.setNavigationContext(clientActiveMainCategory);
            } else {
                const { slug: storeCategorySlug } =
                    $store.getters['navigation/activeMainCategory'] || {};

                if (storeCategorySlug) {
                    setNavigationClientContext($storage, storeCategorySlug);
                }
            }
        },
    },

    mounted() {
        this.updateNavigationClientContext();
        this.handleCreateAndConnectAvatarId();

        window.optimizely?.push?.({
            type: 'activate',
        });

        if (this.isOptimizelyEnabled) {
            // reruns optimizely test after hydration
            const { testOptimizely } = window;

            if (Array.isArray(testOptimizely)) {
                testOptimizely.forEach(test => test());
            }
        }
    },
});
