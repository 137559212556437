import SEARCH_SERVICES_NAMESPACE from '@search/async-services/namespace';

export const NAME = `${SEARCH_SERVICES_NAMESPACE}/catalog`;

export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_SEARCH_SERVICE_DATA = 'GET_SEARCH_SERVICE_DATA';
export const GET_SEARCH_SERVICE_PRODUCTS = 'GET_SEARCH_SERVICE_PRODUCTS';
export const GET_SIMILAR_PRODUCTS_DATA = 'GET_SIMILAR_PRODUCTS_DATA';
export const GET_VISUAL_SEARCH_DATA = 'GET_VISUAL_SEARCH_DATA';
export const GET_SEO_LINKS_DATA = 'GET_SEO_LINKS_DATA';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_STATIC_CONTENT = 'GET_STATIC_CONTENT';
export const GET_COLOR_VARIANTS = 'GET_COLOR_VARIANTS';
export const GET_SPONSORED = 'GET_SPONSORED';
export const GET_CATEGORIES_TRANSLATIONS = 'GET_CATEGORIES_TRANSLATIONS';
export const GET_FILTERS = 'GET_FILTERS';
export const GET_NAVIGATION = 'GET_NAVIGATION';

export const ASYNC_CATALOG_SERVICE_CONFIG = {
    NAME,
    METHODS: {
        GET_SEARCH_DATA,
        GET_SEARCH_SERVICE_DATA,
        GET_SEARCH_SERVICE_PRODUCTS,
        GET_SIMILAR_PRODUCTS_DATA,
        GET_VISUAL_SEARCH_DATA,
        GET_SEO_LINKS_DATA,
        GET_PRODUCTS,
        GET_STATIC_CONTENT,
        GET_COLOR_VARIANTS,
        GET_SPONSORED,
        GET_CATEGORIES_TRANSLATIONS,
        GET_FILTERS,
        GET_NAVIGATION,
    },
};
