<template>
    <img :src="brandLogoSrc" :alt="brandLogoAlt" width="147" height="38" class="brand-logo" />
</template>

<script>
import { BRAND_LOGO, BRAND_LOGO_ALT } from '@localeConfig/keys';

export default {
    name: 'MainLogo',

    created() {
        this.brandLogoSrc = this.$configProvider.getConfig(BRAND_LOGO);
        this.brandLogoAlt = this.$configProvider.getConfig(BRAND_LOGO_ALT);
    },
};
</script>

<style lang="scss">
.brand-logo {
    width: 100px;
    height: 26px;
}

@screen lg {
    .brand-logo {
        width: 200px;
        height: 38px;
    }
}
</style>
