import { DEFAULT_LOCALE_CODE } from '@configs/locale';

import { isObject } from '@assets/object';
import { copyObjectDeep } from '@assets/copyObjectDeep';
import { removeHostPrefix } from '@assets/removeHostPrefix';

export default class BrandConfigCreator {
    constructor(brand, additionalConfig) {
        this.brand = brand;

        this.additionalConfig = additionalConfig || {};
    }

    async getBrandConfig() {
        const { brand } = this;

        const { default: brandConfig } = await import(
            /* webpackChunkName: "brand-config" */ `@configs/x3/brands/${brand}`
        );

        if (!isObject(brandConfig)) {
            throw new Error(
                BrandConfigCreator.createLogMessage(`Brand config "${brand}" does not exist`)
            );
        }

        return brandConfig;
    }

    async getLocaleConfig(locale) {
        if (typeof locale === 'undefined') {
            throw new Error(BrandConfigCreator.createLogMessage('Locale is undefined'));
        }

        const { brand } = this;

        const { default: localeConfig } =
            (await import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "locale-config" */ `@configs/x3/brands/${brand}/locale-config/locales/${locale}`
            )) || null;

        if (localeConfig === null) {
            throw new Error(
                BrandConfigCreator.createLogMessage(
                    `LocaleConfig (${locale}) for ${brand} is not defined.`
                )
            );
        }

        return localeConfig;
    }

    async getLocaleCommonConfig() {
        const { brand } = this;

        const { default: commonLocaleConfig } =
            (await import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "common-locale-config" */ `@configs/x3/brands/${brand}/locale-config/locales/common`
            )) || null;

        if (commonLocaleConfig === null) {
            throw new Error(
                BrandConfigCreator.createLogMessage(
                    `Common locale config for ${brand} is not defined.`
                )
            );
        }

        return commonLocaleConfig;
    }

    async getMergedLocaleConfig(locale) {
        const [commonLocaleConfig, localeConfig] = await Promise.all([
            this.getLocaleCommonConfig(),
            this.getLocaleConfig(locale),
        ]);

        const config = {};

        Object.entries(commonLocaleConfig).forEach(([key, { value }]) => {
            config[key] = value;
        });

        Object.entries(localeConfig).forEach(([key, { value }]) => {
            if (config[key]) {
                console.info(
                    BrandConfigCreator.createLogMessage(
                        `Key "${key}" is already defined in common config for brand ${this.brand}`
                    )
                );
            }

            config[key] = value;
        });

        return config;
    }

    async getConfig(locale) {
        const [{ config: brandConfig }, localeConfig] = await Promise.all([
            this.getBrandConfig(),
            this.getMergedLocaleConfig(locale),
        ]);

        const configs = [
            { type: 'brand', config: copyObjectDeep(brandConfig) },
            { type: 'locale', config: copyObjectDeep(localeConfig) },
            { type: 'additional', config: copyObjectDeep(this.additionalConfig) },
        ];

        const config = {};

        configs.forEach(({ config: cfg }, index) => {
            Object.keys(cfg).forEach(key => {
                if (config[key]) {
                    const previousConfigType = configs[index - 1]?.type || configs[0].type;

                    throw new Error(
                        BrandConfigCreator.createLogMessage(
                            `Key "${key}" is already defined in ${previousConfigType} config`
                        )
                    );
                }

                config[key] = cfg[key];
            });
        });

        return config;
    }

    static createLogMessage(message) {
        return `[BrandConfigCreator]: ${message}`;
    }

    static getCurrentStoreCode(host, locales) {
        const hostWithoutPrefix = removeHostPrefix(host);

        const domainLocaleConfig = locales.find(config => {
            return config.domain && removeHostPrefix(config.domain) === hostWithoutPrefix;
        });

        return domainLocaleConfig?.code || DEFAULT_LOCALE_CODE;
    }

    static getCurrentStoreView(host, storeViews) {
        const hostWithoutPrefix = removeHostPrefix(host);

        let currentStoreView = storeViews.find(
            ({ base_url }) => base_url && removeHostPrefix(base_url) === hostWithoutPrefix
        );

        if (!currentStoreView) {
            currentStoreView = storeViews.find(({ code }) => code === DEFAULT_LOCALE_CODE);
        }

        return currentStoreView;
    }
}
